import React, { useState, useRef } from 'react';
import { propTypes } from '../../../util/types';
import {  useIntl } from '../../../util/reactIntl';
import { AspectRatioWrapper, ResponsiveImage, Modal, Button, Icons } from '../../../components';
import Slider from 'react-slick';
import { isArrayLength } from '../../../util/genericHelpers';
import IconRightArrow from '../../../components/IconRightArrow/IconRightArrow';
import IconBackArrow from '../../../components/IconBackArrow/IconBackArrow';
import '../../../styles/zoomerDefaults.css';
import Viewer from 'react-viewer';

import css from './ListingImageGallery.module.css';
import '../../../styles/slick/slick.css';
import '../../../styles/slick/slick-theme.css';

const isWindowDefined = typeof window !== 'undefined';
const isMobileLayout = isWindowDefined && window.innerWidth < 768;

/**
 * Transforms Sharetribe image data into a simplified image array
 * with `src` and `alt` fields only.
 *
 * @param {Array} imageObjects - Array of image objects from Sharetribe.
 * @returns {Array} - Array in the format [{ src: '', alt: '' }]
 */
function transformImageData(imageObjects) {
  if (!Array.isArray(imageObjects)) return [];

  return imageObjects.map((imgObj, index) => {
    const variants = imgObj?.image?.attributes?.variants;
    const bestVariant = variants?.['listing-card-6x'] || Object.values(variants || {})[0]; // fallback to first variant

    return {
      src: bestVariant?.url || '',
      alt: imgObj?.alt || `Photo ${index + 1}/${imageObjects.length}`,
    };
  });
}

/**
 * The ListingImageGallery component.
 *
 * @component
 * @param {Object} props
 * @param {string} [props.className] - Custom class that extends the default class for the root element
 * @param {string} [props.rootClassName] - Custom class that overrides the default class for the root element
 * @param {Array<propTypes.image>} props.images - The images
 * @param {Array<string>} props.imageVariants - The image variants
 * @param {Array<string>} props.thumbnailVariants - The thumbnail variants
 * @returns {JSX.Element} listing image gallery component
 */
const ListingImageGallery = props => {
  const { images, imageVariants, onManageDisableScrolling = () => false } = props;

  const [showAll, onShowAll] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // { baseImage, zoomSrc, index }
  const [isZoomModalOpen, setZoomModalOpen] = useState(false);

  const intl = useIntl();
  const largeImageRefs = useRef([]);

  const items = images.map((img, i) => {
    return {
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      image: img,
    };
  });

  const renderItem = item => {
    return (
      <AspectRatioWrapper key={item.alt} width={1} height={1} className={css.itemWrapper}>
        <div className={css.itemCentering}>
          <ResponsiveImage
            rootClassName={css.item}
            image={item.image}
            alt={item.alt}
            variants={imageVariants}
          />
        </div>
      </AspectRatioWrapper>
    );
  };

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  function RenderNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconRightArrow className={css.icon} />
      </div>
    );
  }

  function RenderPrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <IconBackArrow className={css.icon} />
      </div>
    );
  }

  const settings = {
    dots: isMobileLayout,
    arrows: !isMobileLayout,
    dotsClass: 'slick-dots slick-thumb',
    fade: true,
    infinite: true,
    lazyLoad: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    pauseOnHover: true,
    nextArrow: <RenderNextArrow />,
    prevArrow: <RenderPrevArrow />,
  };

  const renderSliderData = selectedImage ? (
    <div className={css.gallaryDesktopSec} />
  ) : (
    <>
      {isMobileLayout ? (
        <Slider className={css.gallerySec} {...settings}>
          {items.map(renderItem)}
        </Slider>
      ) : (
        <div className={css.gallaryDesktopSec}> {items?.slice(0, 5).map(renderItem)}</div>
      )}
    </>
  );

  const renderShowAllModal = (
    <Modal
      usePortal
      id="ListingImageGallery"
      modalTitle={`View All (${items?.length})`}
      isOpen={showAll}
      onClose={() => onShowAll(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      isGalleryModal={true}
    >
      <div className={css.modalGallery}>
        <h2>{intl.formatMessage({ id: 'ListingImageGallery.photoTour' })}</h2>
        <div className={css.smallImagesWrapper}>
          {isArrayLength(items) &&
            items.map((item, index) => {
              const img = item.image?.attributes?.variants?.['listing-card']?.url;
              return (
                <div
                  className={css.smallThumbnailImages}
                  key={index}
                  onClick={() =>
                    largeImageRefs.current[index]?.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    })
                  }
                >
                  <div className={css.smallThumbnailGrid}>
                    <img src={img} alt={item.alt || `Image ${index + 1}`} />
                  </div>
                  <div className={css.smallThumbnailInfos}>{`Photo ${index + 1}`}</div>
                </div>
              );
            })}
        </div>

        <div className={css.largeImages}>
          {isArrayLength(items) &&
            items.map((item, index) => {
              const baseImage = item.image?.attributes?.variants?.['listing-card-6x']?.url;
              const zoomSrc = item.image?.attributes?.variants?.['scaled-xlarge']?.url;
              return (
                <div
                  className={css.categoryImages}
                  key={index}
                  ref={el => (largeImageRefs.current[index] = el)}
                >
                  <div className={css.categoryImagesTitle}>
                    {intl.formatMessage({ id: 'ListingImageGallery.imageTitle' })} {index + 1}
                  </div>
                  <div
                    onClick={() => {
                      setSelectedImage({ baseImage, zoomSrc, index });
                      setZoomModalOpen(true);
                    }}
                  >
                    <div className={css.smallThumbnailGrid}>
                      <img src={baseImage} alt={item.alt || `Image ${index + 1}`} />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Modal>
  );

  return (
    <div className={css.gallarySlider}>
      {renderSliderData}
      <div className={css.viewPhotos}>
        <Button onClick={() => onShowAll(!showAll)}>
          {intl.formatMessage({ id: 'ListingImageGallery.showAll' })}({items?.length})
        </Button>

        {isZoomModalOpen && selectedImage ? (
          <Viewer
            visible={isZoomModalOpen}
            noNavbar
            zIndex={9999}
            activeIndex={selectedImage?.index || 0}
            onClose={() => {
              setSelectedImage(null);
              setZoomModalOpen(false);
            }}
            images={transformImageData(items)}
            rotatable={false}
            scalable={false}
            className={css.toolBarContainer}
            zoomSpeed={0.4} // 3-4x zoom increment boost
            drag={true} // ensure panning works after zoom
            zoomable
            downloadable={false}
          />
        ) : (
          renderShowAllModal
        )}
      </div>
    </div>
  );
};

export default ListingImageGallery;